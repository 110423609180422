<template>
  <div class="container">
    <div class="main">
      <!--<div class="top">-->
        <!--<el-input prefix-icon="el-icon-search" placeholder="搜索工作岗位"></el-input>-->
      <!--</div>-->
      <div class="mid">
        <div class="mid_top">
          <div class="mid_top_left">
            <div class="left_top">
              <div class="left_top_name">{{info.name}}</div>
              <div class="left_top_money"> {{info.salary}}K </div>
            </div>
            <div class="left_bottom">{{ info.intro }}</div>
          </div>
          <!--<a class="mid_top_right" @click.stop="toBoss(info)">-->
            <!--BOSS直聘沟通-->
          <!--</a>-->
          <a class="mid_top_right"
             :href="
                  info.id == 1 ? 'https://www.zhipin.com/job_detail/361cf64fe3162b000nx62dS9E1U~.html?ka=search_list_jname_2_blank&lid=2Fks5DRZ5aK.search.2&securityId=Ih9W1DhVOgeCI-x1X3_F42WqGIE6o_DcMh0SR5gYLV6Zt7UaCEDRtT_4HXFy5qT_Qju5zolrFjDFReCZSQxIE8ZJeo3Kcjzb4fzXiOjPSBtQWvYR' :
                  info.id == 2 ? 'https://www.zhipin.com/job_detail/4ca9edd3400999810nd_3Ni-EVs~.html?ka=search_list_jname_10_blank&lid=2Fks5DRZ5aK.search.10&securityId=xQ-2DNIEf2dqg-21f4qlxdsazZBIiDpnxcear5PXYRIKFEkg5_bBhwQjCJB9Cjjo-gndOQwqrPaM2iRuoQ0Wydbfhx8-dgkWo4fpU1QVvPQRkfjW' :
                  info.id == 3 ? 'https://www.zhipin.com/job_detail/e38e5fe9b2f4ba230nx62dS9EVU~.html?ka=search_list_jname_4_blank&lid=2Fks5DRZ5aK.search.4&securityId=JsSxF14hv2AN6-O1D2rwuhBVY4vkSgHJ11qQqiaWq2RX48Kxw2IJvcknAooUoqfB3Q8HwtmkkZwr3xUm-soQJ7XLKZwqSO_LO9GIN0NpdnVOi-md' :
                  info.id == 4 ? 'https://www.zhipin.com/gongsir/aa445caa8331b5421XZ_2ty4GQ~~.html?page=1&ka=page-1':
                  info.id == 5 ? 'https://www.zhipin.com/gongsir/aa445caa8331b5421XZ_2ty4GQ~~.html?page=1&ka=page-1': ''"
             target="_blank"
          >BOSS直聘</a>
        </div>
        <div class="mid_line"></div>
        <div class="mid_bottom">
          <div class="mid_item">
            <div class="mid_item_top"> 职责： </div>
            <div class="mid_item_box">
              <div class="text" v-for="item in info.resList" :key="item"> {{item.title}}</div>
            </div>
          </div>
          <div class="mid_item">
            <div class="mid_item_top"> 要求： </div>
            <div class="mid_item_box">
              <div class="text" v-for="item in info.reqList" :key="item"> {{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "job_detail",
    data(){
      return {
        list: [
          {
            name: '工作职责',
            resp: [
              { title: '参与项目需求的讨论，负责部分功能模块的开发；' },
              { title: '对开发任务能够准确理解，并且能够保质保量完成；' },
              { title: '喜欢去看及尝试最新的技术，追求编写优雅的代码，从技术趋势和思路上能影响技术团队；' },
              { title: '具备良好的表达和沟通能力；具备极强的团队精神和合作精神。' },
            ]
          },
          {
            name: '工作要求',
            resp: [
              { title: '熟悉 J2EE 开源框架和技术，如 Spring, Mybatis, Hibernate；' },
              { title: '熟练使用至少一种主流数据库 MySQL, Postgresql, Oracle；' },
              { title: '熟悉 HTML、JavaScript、CSS、XML 等 Web 开发领域及相关技术；' },
              { title: '熟悉 W3C 标准与 ES 规范，熟悉 Web 语义化;' },
              { title: '熟悉面向对象的程序设计方法，熟悉了解各种数据结构，算法模型以及常用设计模式；' },
              { title: '熟练代码版本管理工具 svn, git；' },
              { title: '一年以上工作经验' },
            ]
          },
        ],
        info: {},
      }

    },
    methods: {
      toBoss(){

      }
    },
    created(){
      this.info = JSON.parse(localStorage.getItem('jobInfo'))
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container {
    background #F5F5F7
    display flex
    align-items center
    justify-content center

    .main {
      width 1640px

      .top {
        margin-top 60px
      }
      .mid{
        display flex
        flex-flow column
        margin-top 60px
        .mid_top{
          display flex
          align-items center
          justify-content space-between
          .mid_top_left{
            display flex
            flex-flow column
            .left_top{
              display flex
              .left_top_name{
                font-size 56px
                font-weight bold
                color #343A40
              }
              .left_top_money{
                font-size 56px
                font-weight 500
                color #007BFF
                margin-left 40px
              }
            }
            .left_bottom{
              font-size 24px
              color #343A40
              margin-top 12px
            }
          }
          .mid_top_right{
            width 372px
            height 93px
            text-align center
            line-height 93px
            background #5FD6C9
            color #FFF
            font-size 32px
            font-weight 500
            border-radius 8px
            cursor pointer
            text-decoration none
          }
        }
        .mid_line{
          width 1226px
          height 1px
          background #E2E6EA
          margin-top 40px
        }
        .mid_bottom{
          display flex
          flex-flow column
          margin-bottom 40px
          .mid_item{
            display flex
            flex-flow column
            margin-top 80px
            .mid_item_top{
              font-size 28px
              font-weight bold
              color #343A40
            }
            .mid_item_box{
              display flex
              flex-flow column
              margin-top 40px
              .text{
                font-size 20px
                line-height 30px
                color #6C757D
              }
            }
          }
        }
      }
    }
  }
</style>
